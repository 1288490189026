export const newFilters = {
	features: [
		{
			name: 'Outdoor',
			synonyms: ['Outdoors', 'Garden furniture', 'Patio furniture'],
		},
		{ name: 'Reclining', synonyms: ['Recliner'] },
		{ name: 'Wingback', synonyms: [] },
		{ name: 'Nesting', synonyms: ['Nested'] },
		{ name: 'Free Shipping', synonyms: [] },
		{ name: ' Set ', synonyms: ['Dining set'] },
		{ name: 'Sleeper', synonyms: [] },
		{ name: ' Shelf ', synonyms: [] },
		{ name: 'Seafoam', synonyms: [] },
		{ name: 'Drawers', synonyms: ['Drawer'] },
		{ name: 'Beveled', synonyms: [] },
		{
			name: 'Metal base',
			synonyms: [
				'Iron Base',
				'Brass Base',
				'Iron Base',
				'Steel Base',
				'Gold Base',
			],
		},
		{ name: 'Swivel', synonyms: [] },
		{ name: ' Round', synonyms: ['Round,'] },
		{ name: ' Square', synonyms: ['Square,'] },
		{
			name: 'Media/Entertainment',
			synonyms: ['Entertainment Center', 'Media'],
		},
		{ name: 'Stand up', synonyms: ['Sit/stand', 'Stand up'] },
		{ name: 'Reversible', synonyms: [] },
		{ name: 'Nailhead', synonyms: ['Nail head'] },
		{ name: 'Padded', synonyms: [] },
		{ name: 'X legs', synonyms: [] },
		{ name: 'Adjustable Height', synonyms: ['Stanup desk', 'Sit/stand'] },
		{ name: 'Adjustable', synonyms: [] },
		{ name: 'Wood top', synonyms: [] },
		{ name: 'Marble top', synonyms: [] },
		{ name: 'Tufted', synonyms: [] },
	],
	colors: [
		{ name: ' Red', synonyms: ['Red,'] },
		{ name: ' Orange', synonyms: ['Orange,'] },
		{ name: ' Yellow', synonyms: ['Yellow,'] },
		{ name: ' Green', synonyms: ['Green,'] },
		{ name: ' Turquoise', synonyms: ['Turquoise,'] },
		{ name: ' Blue', synonyms: ['Blue,'] },
		{ name: ' Purple', synonyms: ['Purple,'] },
		{ name: ' Pink', synonyms: ['Pink,'] },
		{ name: ' Black', synonyms: ['Black,'] },
		{ name: ' White', synonyms: ['White,'] },
		{ name: ' Gray', synonyms: ['Gray,'] },
		{ name: ' Brown', synonyms: ['Brown,'] },
		{ name: ' Gold', synonyms: ['Golden', 'Gold,'] },
		{ name: ' Silver', synonyms: ['Silver,'] },
		{ name: ' Copper', synonyms: ['Copper,'] },
		{ name: ' Transparent', synonyms: ['Transparent,'] },
	],
	brands: [
		{
			name: 'Legacy Furniture',
			synonyms: [],
		},
		{
			name: 'George Oliver',
			synonyms: [],
		},
		{
			name: 'Stanley Furniture',
			synonyms: [],
		},
		{
			name: 'Terrace Living',
			synonyms: [],
		},
		{
			name: 'Ethan Allen',
			synonyms: [],
		},
		{
			name: 'West Elm',
			synonyms: [],
		},
		{
			name: 'Ikea',
			synonyms: [],
		},
		{
			name: 'Article',
			synonyms: [],
		},
		{
			name: 'Article Sven',
			synonyms: [],
		},
		{
			name: 'Intertek',
			synonyms: [],
		},
		{
			name: 'Harvest',
			synonyms: [],
		},
		{
			name: 'Restoration Hardware',
			synonyms: [' RH '],
		},
		{
			name: 'Ace Interior',
			synonyms: [],
		},
		{
			name: 'Pottery Barn',
			synonyms: [],
		},
		{
			name: 'La Z Boy',
			synonyms: ['La-Z-Boy'],
		},
		{
			name: 'Walker Edison',
			synonyms: [],
		},
		{
			name: 'Ashley Furniture',
			synonyms: ['Ashley Signature Furniture'],
		},

		{
			name: 'Henredon',
			synonyms: [],
		},
		{
			name: 'Thomasville Allegro',
			synonyms: [],
		},
		{
			name: 'Henry Link ',
			synonyms: ['Henry Link Bali hai'],
		},
		{
			name: 'Dixie Furniture',
			synonyms: [],
		},
		{
			name: 'Century Furniture',
			synonyms: [],
		},
		{
			name: 'Poppin',
			synonyms: [],
		},
		{
			name: 'Donghia',
			synonyms: [],
		},
		{
			name: 'Crate And Barrel',
			synonyms: ['Crate & Barrel', 'Crate&Barrel'],
		},
		{
			name: 'Ersa',
			synonyms: [],
		},
		{
			name: 'Skyline Furniture',
			synonyms: [],
		},
		{
			name: 'Steelcase',
			synonyms: [],
		},
		{
			name: 'Vitra',
			synonyms: [],
		},
		{
			name: 'Oliver Space',
			synonyms: [],
		},
		{
			name: 'Four Hands',
			synonyms: [],
		},
		{
			name: 'Galaxy Home',
			synonyms: [],
		},
		{
			name: 'Room And Board',
			synonyms: ['Room & Board', 'Room&Board'],
		},
		{
			name: 'Modway',
			synonyms: [],
		},
		{
			name: 'Armen Living',
			synonyms: [],
		},
		{
			name: 'Unique Loom',
			synonyms: [],
		},
		{
			name: 'Magnolia Home',
			synonyms: [],
		},
		{
			name: 'Surya',
			synonyms: [],
		},
		{
			name: 'Nuloom',
			synonyms: [],
		},
		{
			name: 'Safavieh',
			synonyms: [],
		},
		{
			name: 'Stressless',
			synonyms: [],
		},
		{
			name: 'CB2',
			synonyms: [],
		},

		{
			name: 'Jonathan Louis',
			synonyms: [],
		},
		{
			name: 'Zuo Modern',
			synonyms: ['Zuo'],
		},
		{
			name: 'Sauder',
			synonyms: [],
		},
		{
			name: 'Froy',
			synonyms: [],
		},

		{
			name: 'Abbyson',
			synonyms: [],
		},
		{
			name: 'Coaster',
			synonyms: [],
		},
		{
			name: 'Design Within Reach',
			synonyms: ['DWR'],
		},
		{
			name: 'Scandinavian Designs',
			synonyms: [],
		},
		{
			name: 'Westnofa',
			synonyms: [],
		},
		{
			name: 'Maitland Smith',
			synonyms: [],
		},
		{
			name: 'World Market',
			synonyms: [],
		},
		{
			name: 'Pacific Coast Lighting',
			synonyms: [],
		},
		{
			name: 'Uttermost',
			synonyms: [],
		},
		{
			name: 'Jonathan Adler',
			synonyms: [],
		},

		{
			name: 'Desede',
			synonyms: ['De Sede'],
		},
		{
			name: 'Humanscale',
			synonyms: [],
		},
		{
			name: 'Hd Buttercup',
			synonyms: [],
		},
		{
			name: 'Madison Park',
			synonyms: [],
		},
		{
			name: 'Jarvis',
			synonyms: [],
		},
		{
			name: 'Worlds Away',
			synonyms: [],
		},
		{
			name: 'Hickory',
			synonyms: ['Hickory White'],
		},
		{
			name: 'Bernhardt',
			synonyms: [],
		},
		{
			name: 'Mitchell Gold',
			synonyms: [],
		},
		{
			name: 'Brandt',
			synonyms: [],
		},

		{
			name: 'Tommy Bahama',
			synonyms: [],
		},
		{
			name: 'Roche Bobois',
			synonyms: [],
		},
		{
			name: 'Herman Miller',
			synonyms: ['HM'],
		},
		{
			name: 'Eande Co Ltd',
			synonyms: [],
		},
		{
			name: 'E And E',
			synonyms: ['E & E', 'E&E'],
		},
		{
			name: 'Williams Sonoma',
			synonyms: [],
		},
		{
			name: 'New Pacific Direct',
			synonyms: [],
		},
		{
			name: 'Artcom',
			synonyms: [],
		},
		{
			name: 'Eq3',
			synonyms: [],
		},
		{
			name: 'Lifestyle Solutions',
			synonyms: [],
		},
		{
			name: 'Zinus',
			synonyms: [],
		},
		{
			name: 'Pier 1',
			synonyms: [],
		},
		{
			name: 'American Leather',
			synonyms: [],
		},
		{
			name: 'Mitchell Gold Bob Williams',
			synonyms: ['Mitchell Gold Bob'],
		},
		{
			name: 'American Of Martinsville',
			synonyms: [],
		},
		{
			name: 'Stiffel',
			synonyms: [],
		},
		{
			name: 'Urban Outfitters',
			synonyms: [],
		},

		{
			name: 'Fritz Hansen',
			synonyms: [],
		},
		{
			name: 'Knoll',
			synonyms: [],
		},
		{
			name: 'Dixie Aloha',
			synonyms: [],
		},
		{
			name: 'Michael Amini',
			synonyms: [],
		},
		{
			name: 'Rejuvenation',
			synonyms: [],
		},
		{
			name: 'Ace Interiors',
			synonyms: [],
		},
		{
			name: 'All Modern',
			synonyms: [],
		},
		{
			name: 'Z Gallerie',
			synonyms: [],
		},
		{
			name: 'Conset',
			synonyms: [],
		},
		{
			name: 'Sunpan',
			synonyms: [],
		},
		{
			name: 'Lumisource',
			synonyms: [],
		},
		{
			name: 'Rove Concepts',
			synonyms: [],
		},
		{
			name: 'Fully',
			synonyms: [],
		},
		{
			name: 'William Sonoma',
			synonyms: [],
		},
		{
			name: 'Madison Home',
			synonyms: [],
		},
		{
			name: 'Cost Plus World_market',
			synonyms: [],
		},
		{
			name: 'Adesso',
			synonyms: [],
		},
		{
			name: 'Duralee',
			synonyms: [],
		},
		{
			name: 'Project 62',
			synonyms: [],
		},
		{
			name: 'Kincaid',
			synonyms: [],
		},
		{
			name: 'Bombay Company',
			synonyms: [],
		},
		{
			name: 'Rohn Richard',
			synonyms: [],
		},
		{
			name: 'Aspen Furniture',
			synonyms: [],
		},
		{
			name: 'Olliix',
			synonyms: [],
		},
		{
			name: 'Domicil',
			synonyms: [],
		},
		{
			name: 'Serena And Lily',
			synonyms: [],
		},
		{
			name: 'Pier 1 Imports',
			synonyms: [],
		},
		{
			name: 'Camerich',
			synonyms: [],
		},
		{
			name: 'Noble House',
			synonyms: [],
		},
		{
			name: 'Burrow',
			synonyms: [],
		},
		{
			name: 'Hooker Furniture',
			synonyms: [],
		},
		{
			name: 'Kimball',
			synonyms: [],
		},
		{
			name: 'Artistica',
			synonyms: [],
		},
		{
			name: 'Blu Dot',
			synonyms: [],
		},
		{
			name: 'Living Spaces',
			synonyms: [],
		},
		{
			name: 'Arhaus',
			synonyms: [],
		},
		{
			name: 'Vanguard',
			synonyms: [],
		},
		{
			name: 'Hekman Furniture',
			synonyms: [],
		},
		{
			name: 'Mastercraft',
			synonyms: [],
		},
		{
			name: 'Nichols And Stone',
			synonyms: [],
		},
		{
			name: 'Mcguire Furniture',
			synonyms: [],
		},
		{
			name: 'Thomasville',
			synonyms: [],
		},
		{
			name: 'Sarreid Ltd',
			synonyms: [],
		},
		{
			name: 'John Widdicomb',
			synonyms: [],
		},
		{
			name: 'Christian Liaigre',
			synonyms: [],
		},
		{
			name: 'Brownstone Furniture',
			synonyms: [],
		},
		{
			name: 'Twin Star International',
			synonyms: [],
		},
		{
			name: 'Nourison',
			synonyms: [],
		},
		{
			name: 'Drexel Heritage',
			synonyms: [],
		},
		{
			name: 'Cb2 Crate And_barrel',
			synonyms: [],
		},
		{
			name: 'Councill',
			synonyms: [],
		},
		{
			name: 'Stickley',
			synonyms: [],
		},
		{
			name: 'Hooker',
			synonyms: [],
		},
		{
			name: 'Sherrill',
			synonyms: [],
		},
		{
			name: 'Modway Furniture',
			synonyms: [],
		},
		{
			name: 'Natuzzi',
			synonyms: [],
		},
		{
			name: 'Latitude Run',
			synonyms: [],
		},
		{
			name: 'Swedese',
			synonyms: [],
		},
		{
			name: 'Cassina',
			synonyms: [],
		},
		{
			name: 'Lane',
			synonyms: [],
		},
		{
			name: 'Cfc',
			synonyms: [],
		},
		{
			name: 'Amish Traditions',
			synonyms: [],
		},
		{
			name: 'Baker Furniture',
			synonyms: [],
		},

		{
			name: 'Pulaski',
			synonyms: [],
		},
		{
			name: 'Gus',
			synonyms: [],
		},
		{
			name: 'Bassett Furniture',
			synonyms: [],
		},
		{
			name: 'Porter Int L_design',
			synonyms: [],
		},
		{
			name: 'Somerset Bay',
			synonyms: [],
		},
		{
			name: 'Salem House',
			synonyms: [],
		},
		{
			name: 'Henkel Harris',
			synonyms: [],
		},
		{
			name: 'Arbek',
			synonyms: [],
		},
		{
			name: 'Teknion',
			synonyms: [],
		},
		{
			name: 'Flexsteel',
			synonyms: [],
		},
		{
			name: 'Bombay Co',
			synonyms: [],
		},
		{
			name: 'Inside Weather',
			synonyms: [],
		},
		{
			name: 'Wayfair',
			synonyms: [],
		},
		{
			name: 'Ballard Designs',
			synonyms: [],
		},
		{
			name: 'Hoot Judkins',
			synonyms: [],
		},
		{
			name: 'Palliser',
			synonyms: [],
		},
		{
			name: 'Zone',
			synonyms: [],
		},
		{
			name: 'Lumas',
			synonyms: [],
		},
		{
			name: 'Broyhill',
			synonyms: [],
		},
		{
			name: 'Aspen Home',
			synonyms: [],
		},
		{
			name: 'Coaster Furniture',
			synonyms: [],
		},
		{
			name: 'Everly Quinn',
			synonyms: [],
		},
		{
			name: 'Pottery Barn Teen',
			synonyms: [],
		},
		{
			name: 'Home Pop',
			synonyms: [],
		},

		{
			name: 'White Furniture Co',
			synonyms: [],
		},
		{
			name: 'Roche Bubois',
			synonyms: [],
		},

		{
			name: 'Lexington',
			synonyms: [],
		},
		{
			name: 'Modern Design',
			synonyms: [],
		},
		{
			name: 'One Kings Lane',
			synonyms: [],
		},
		{
			name: 'Pottery Barn Kids',
			synonyms: [],
		},
		{
			name: 'Target',
			synonyms: [],
		},
		{
			name: 'Universal Furniture',
			synonyms: [],
		},
		{
			name: 'Baumritter',
			synonyms: [],
		},

		{
			name: 'Macys',
			synonyms: ['Macyas'],
		},
		{
			name: 'Robb And Stucky',
			synonyms: [],
		},
		{
			name: 'Fusion Furniture',
			synonyms: [],
		},
		{
			name: 'Sunbrella',
			synonyms: [],
		},
		{
			name: 'Pearson',
			synonyms: [],
		},
		{
			name: 'United Furniture',
			synonyms: [],
		},

		{
			name: 'Rowe Furniture',
			synonyms: [],
		},
		{
			name: 'Moes Home Collection',
			synonyms: [],
		},
		{
			name: 'Caracole',
			synonyms: [],
		},
		{
			name: 'Dellarobbia',
			synonyms: [],
		},
		{
			name: 'Traditions',
			synonyms: [],
		},
		{
			name: 'Coaster Fine Furniture',
			synonyms: ['Coaster Furniture'],
		},
		{
			name: 'Moeas Home Collection',
			synonyms: [],
		},
		{
			name: 'Lee Industries',
			synonyms: [],
		},

		{
			name: 'A Rudin',
			synonyms: [],
		},
		{
			name: 'Nicoletti',
			synonyms: [],
		},
		{
			name: 'Noble House Home',
			synonyms: [],
		},
		{
			name: 'M A D',
			synonyms: [],
		},
		{
			name: 'Oliixx',
			synonyms: [],
		},
		{
			name: 'Home Trends And Design',
			synonyms: ['Home Trends And Designs'],
		},
		{
			name: 'Diamond Sofa',
			synonyms: [],
		},

		{
			name: 'Lifeform',
			synonyms: [],
		},
		{
			name: 'Traditional Derby',
			synonyms: [],
		},
		{
			name: 'Costco',
			synonyms: [],
		},
		{
			name: 'Tov Furniture',
			synonyms: [],
		},
		{
			name: 'Hewitt',
			synonyms: [],
		},
		{
			name: 'Wade Logan Wayfair',
			synonyms: [],
		},
		{
			name: 'Bo Concept',
			synonyms: [],
		},
		{
			name: 'Relax The Back',
			synonyms: [],
		},
		{
			name: 'Sofa By Fancy',
			synonyms: [],
		},
		{
			name: 'Turnstone',
			synonyms: [],
		},
		{
			name: 'Calia',
			synonyms: [],
		},
		{
			name: 'Ekornes',
			synonyms: [],
		},
		{
			name: 'Halo Asia',
			synonyms: [],
		},
		{
			name: 'Marmo',
			synonyms: [],
		},
		{
			name: 'Flair',
			synonyms: [],
		},
		{
			name: 'Dakota Jackson',
			synonyms: [],
		},
		{
			name: 'Cisco Brothers',
			synonyms: [],
		},
		{
			name: 'Palecek',
			synonyms: [],
		},
		{
			name: 'Breuners',
			synonyms: [],
		},
		{
			name: 'Sphaus',
			synonyms: [],
		},
		{
			name: 'Tov',
			synonyms: [],
		},
		{
			name: 'Barclay Butera Lifestyle',
			synonyms: [],
		},
		{
			name: 'Robert Michael Ltd',
			synonyms: [],
		},
		{
			name: 'Summer Hill',
			synonyms: [],
		},
		{
			name: 'Drexel Accolade',
			synonyms: [],
		},
		{
			name: 'Theodore Alexander',
			synonyms: [],
		},
		{
			name: 'Kindel',
			synonyms: [],
		},
		{
			name: 'Hickory Chair',
			synonyms: [],
		},
		{
			name: 'Klaussner',
			synonyms: [],
		},
		{
			name: 'Copenhagen',
			synonyms: [],
		},
		{
			name: 'Bradington Young',
			synonyms: [],
		},
		{
			name: 'The Bombay Company',
			synonyms: [],
		},
		{
			name: 'Lazy Boy',
			synonyms: [],
		},
		{
			name: 'Kincaid Furniture',
			synonyms: [],
		},
		{
			name: 'Norwalk',
			synonyms: [],
		},
		{
			name: 'Universal',
			synonyms: [],
		},
		{
			name: 'Liberty Furniture',
			synonyms: [],
		},
		{
			name: 'Winners Only',
			synonyms: [],
		},
		{
			name: 'Barcalounger',
			synonyms: [],
		},
		{
			name: 'Hokanson And Dix',
			synonyms: [],
		},
		{
			name: 'Mcdowell And Craig',
			synonyms: [],
		},
		{
			name: 'Overstock',
			synonyms: [],
		},
		{
			name: 'Zocalo',
			synonyms: [],
		},
		{
			name: 'Homelegance',
			synonyms: [],
		},
		{
			name: 'Home Meridian',
			synonyms: [],
		},
		{
			name: 'Powell',
			synonyms: [],
		},
		{
			name: 'Trojan',
			synonyms: [],
		},
		{
			name: 'Visual Comfort Chapman_and_meyers',
			synonyms: [],
		},
		{
			name: 'Visual Comfort',
			synonyms: [],
		},
		{
			name: 'Echo',
			synonyms: [],
		},
		{
			name: 'Hisashi Otsuka',
			synonyms: [],
		},
		{
			name: 'Artist Dennis Carney',
			synonyms: [],
		},
		{
			name: 'Baronet Furniture',
			synonyms: [],
		},
		{
			name: 'Riverside Furniture',
			synonyms: [],
		},
		{
			name: 'Bova',
			synonyms: [],
		},
		{
			name: 'Amisco',
			synonyms: [],
		},
		{
			name: 'Homecoming By Kincaid',
			synonyms: [],
		},
		{
			name: 'Stephen Miller Gallery',
			synonyms: [],
		},
		{
			name: 'Sumter Cabinet Co',
			synonyms: [],
		},
		{
			name: 'Dale',
			synonyms: [],
		},
		{
			name: 'Hill Brin',
			synonyms: [],
		},
		{
			name: 'Walter Of Wabash',
			synonyms: [],
		},
		{
			name: 'Gary Mccloy',
			synonyms: [],
		},
		{
			name: 'Mobital',
			synonyms: [],
		},
		{
			name: 'Parker Living Furniture',
			synonyms: [],
		},
		{
			name: 'Violino',
			synonyms: [],
		},
		{
			name: 'Copeland Furniture',
			synonyms: [],
		},
		{
			name: 'Italsofa',
			synonyms: [],
		},
		{
			name: 'Benny Linden',
			synonyms: [],
		},
		{
			name: 'Benny Linden Design',
			synonyms: [],
		},
		{
			name: 'Bdi',
			synonyms: [],
		},
		{
			name: 'Calia Italia',
			synonyms: [],
		},
		{
			name: 'Artisan House',
			synonyms: [],
		},
		{
			name: 'Interlude Home',
			synonyms: [],
		},
		{
			name: 'Homey Design Furniture',
			synonyms: [],
		},
		{
			name: 'Cal Shops',
			synonyms: [],
		},
		{
			name: 'Forum',
			synonyms: [],
		},
		{
			name: 'Gus Modern',
			synonyms: [],
		},
		{
			name: 'Ophelia And Co',
			synonyms: [],
		},
		{
			name: 'Schonbek Swarovski',
			synonyms: [],
		},
		{
			name: 'Creative Elegance',
			synonyms: [],
		},
		{
			name: 'Holly Hunt',
			synonyms: [],
		},
		{
			name: 'Sierra Living',
			synonyms: [],
		},
		{
			name: 'Stone Creek Classic_by_moroni',
			synonyms: [],
		},
		{
			name: 'Havana',
			synonyms: [],
		},
		{
			name: 'Veranda Classics Chairs',
			synonyms: [],
		},
		{
			name: 'Bed Bath And Beyond',
			synonyms: [
				'Bed Bath & Beyond',
				'Bed, Bath & Beyond',
				'Bed Bath&Beyond',
				'Bed, Bath&Beyond',
			],
		},
		{
			name: 'Bmc Furniture',
			synonyms: [],
		},
		{
			name: 'Rivers Edge',
			synonyms: [],
		},
		{
			name: 'Horizon Art',
			synonyms: [],
		},
		{
			name: 'Collezione Europa',
			synonyms: [],
		},
		{
			name: 'P And P_chair_company',
			synonyms: [],
		},
		{
			name: 'New Classic Home_furnishings',
			synonyms: [],
		},
		{
			name: 'Eurohaus',
			synonyms: [],
		},
		{
			name: 'Glory Furniture',
			synonyms: [],
		},
		{
			name: 'Smithe Craft',
			synonyms: [],
		},
		{
			name: 'All Wood Treasures_company',
			synonyms: [],
		},
		{
			name: 'New Classic Furniture',
			synonyms: [],
		},
		{
			name: 'Klassner Furniture',
			synonyms: [],
		},
		{
			name: 'Lt Designs',
			synonyms: [],
		},
		{
			name: 'Clayton Marcus',
			synonyms: [],
		},
		{
			name: 'Pride Mobility',
			synonyms: [],
		},
		{
			name: 'Ts Berry',
			synonyms: [],
		},
		{
			name: 'Hammary Furniture',
			synonyms: [],
		},
		{
			name: 'International Furniture Direct',
			synonyms: [],
		},
		{
			name: 'Aico',
			synonyms: [],
		},
		{
			name: 'Mallin',
			synonyms: [],
		},
		{
			name: 'Art Furniture',
			synonyms: [],
		},
		{
			name: 'Maiden Home',
			synonyms: [],
		},
	],
	materials: [
		{ name: 'Wood', synonyms: [], includes: [] },
		{ name: 'Glass', synonyms: [], includes: [] },
		{ name: 'Metal', synonyms: [], includes: [] },
		{ name: 'Oak', synonyms: [], includes: ['Wood'] },
		{ name: 'Ceramic', synonyms: [], includes: [] },
		{ name: 'Velvet', synonyms: [], includes: [] },
		{ name: 'Fabric', synonyms: [], includes: [] },
		{ name: 'Brass', synonyms: [], includes: ['Metal'] },
		{ name: 'Veneer', synonyms: [], includes: [] },
		{ name: 'Leather', synonyms: [], includes: [] },
		{ name: 'Solid Wood', synonyms: [], includes: ['Wood', 'Hardwood'] },
		{ name: 'Hardwood', synonyms: [], includes: ['Wood', 'Solid Wood'] },
		{ name: 'Marble', synonyms: [], includes: [] },
		{ name: 'Faux Marble', synonyms: [], includes: ['Marble'] },
		{ name: 'Faux Stone', synonyms: [], includes: ['Stone'] },
		{ name: 'Laminate', synonyms: [], includes: [] },
		{ name: 'Concrete', synonyms: [], includes: [] },
		{ name: 'Pleather', synonyms: [], includes: ['Leather'] },
		{ name: 'Steel', synonyms: [], includes: ['Metal'] },
		{ name: 'Rosewood', synonyms: [], includes: ['Wood'] },
		{ name: 'Chrome', synonyms: [], includes: [] },
		{ name: 'Stainless Steel', synonyms: [], includes: ['Metal', 'Steel'] },
		{ name: 'Porcelain', synonyms: [], includes: [] },
		{ name: 'Iron', synonyms: [], includes: ['Metal'] },
		{ name: 'Suede', synonyms: [], includes: [] },
		{ name: 'Wool', synonyms: [], includes: ['Fabric'] },
		{ name: 'Mahogany', synonyms: [], includes: ['Wood'] },
		{ name: 'Nickel', synonyms: [], includes: ['Metal'] },
		{ name: 'Faux Bamboo', synonyms: [], includes: ['Bamboo'] },
		{ name: 'Lacquer', synonyms: [], includes: [] },
		{ name: 'Faux Wood', synonyms: [], includes: ['Wood'] },
		{ name: 'Walnut', synonyms: [], includes: ['Wood'] },
		{ name: 'Bamboo', synonyms: [], includes: [] },
		{ name: 'Vinyl', synonyms: [], includes: [] },
		{ name: 'Aluminum', synonyms: [], includes: ['Metal'] },
		{ name: 'Burl wood', synonyms: [], includes: ['Wood'] },
		{ name: 'Plastic', synonyms: [], includes: [] },
		{ name: 'Polypropylene', synonyms: [], includes: [] },
		{ name: 'Teak', synonyms: [], includes: ['Wood'] },
		{ name: 'Linen', synonyms: [], includes: ['Fabric'] },
		{ name: 'Cherry Wood', synonyms: [], includes: ['Wood'] },
		{ name: 'Canvas', synonyms: [], includes: [] },
		{ name: 'Copper', synonyms: [], includes: ['Metal'] },
		{ name: 'Faux Leather', synonyms: [], includes: ['Leather'] },
		{ name: 'Crystal', synonyms: [], includes: ['Glass'] },
		{ name: 'Birch', synonyms: [], includes: ['Wood'] },
		{ name: 'Maple', synonyms: [], includes: ['Wood'] },
		{ name: 'Stone', synonyms: [], includes: [] },
		{ name: 'Particle Board', synonyms: [], includes: ['Wood'] },
		{ name: 'Acacia Wood', synonyms: [], includes: ['Wood'] },
		{ name: 'Acrylic', synonyms: [], includes: ['Synthetic'] },
		{ name: 'Synthetic', synonyms: [], includes: [] },
		{ name: 'Gold Leaf', synonyms: [], includes: ['Metal'] },
		{ name: 'Cotton', synonyms: [], includes: ['Fabric'] },
		{ name: 'Cast Iron', synonyms: [], includes: ['Metal'] },
		{ name: 'Pine', synonyms: [], includes: ['Wood'] },
		{ name: 'Rubberwood', synonyms: [], includes: ['Wood'] },
		{ name: 'Cowhide', synonyms: [], includes: [] },
		{ name: 'Quartz', synonyms: [], includes: [] },
		{ name: 'Plywood', synonyms: [], includes: ['Wood'] },
		{ name: 'Silk', synonyms: [], includes: ['Fabric'] },
		{ name: 'Paper', synonyms: [], includes: [] },
		{ name: 'Seagrass', synonyms: [], includes: [] },
		{ name: 'Polyester', synonyms: [], includes: ['Fabric'] },
		{ name: 'Fiberglass', synonyms: [], includes: [] },
		{ name: 'Mdf', synonyms: [], includes: ['Wood'] },
		{ name: 'Textile', synonyms: [], includes: ['Fabric'] },
	],
	styles: [
		{ name: 'Italian', synonyms: ['Italy'], includes: [] },
		{ name: 'Made in Italy', synonyms: [], includes: ['Italian'] },
		{ name: 'Antique', synonyms: [], includes: [] },
		{ name: 'Oxford', synonyms: [], includes: [] },
		{
			name: 'Mid Century Modern',
			synonyms: ['Mid Century', 'MCM', 'Mid-Century'],
			includes: [],
		},
		{ name: 'Post Modern', synonyms: [], includes: ['Modern'] },
		{ name: 'Contemporary', synonyms: [], includes: [] },
		{ name: 'Transitional', synonyms: [], includes: [] },
		{ name: 'Modern', synonyms: [], includes: ['Contemporary'] },
		{ name: 'Industrial', synonyms: [], includes: [] },
		{ name: 'Scandinavian', synonyms: [], includes: ['Mid Century Modern'] },
		{ name: 'Chinese', synonyms: [], includes: ['Asian'] },
		{ name: 'Rattan', synonyms: [], includes: [] },
		{ name: 'Coastal', synonyms: [], includes: [] },
		{ name: 'Classic', synonyms: [], includes: [] },
		{ name: 'Rustic', synonyms: [], includes: [] },
		{ name: '20th Century', synonyms: [], includes: [] },
		{ name: 'Chippendale', synonyms: [], includes: [] },
		{ name: 'Transitional', synonyms: [], includes: [] },
		{ name: 'Vintage', synonyms: [], includes: [] },
		{ name: 'Hollywood Regency', synonyms: [], includes: [] },
		{ name: 'Chinoiserie', synonyms: [], includes: [] },
		{ name: 'Boho', synonyms: [], includes: [] },
		{ name: 'Boho Chic', synonyms: [], includes: ['Boho'] },
		{ name: 'French Provincial', synonyms: [], includes: ['French Style'] },
		{ name: 'Shabby', synonyms: [], includes: [] },
		{ name: 'Louis XV', synonyms: [], includes: [] },
		{ name: 'Art Deco', synonyms: [], includes: [] },
		{ name: 'Asian', synonyms: [], includes: [] },
		{ name: 'Farmhouse', synonyms: [], includes: [] },
		{ name: 'Oriental', synonyms: [], includes: [] },
		{ name: 'Luxury', synonyms: [], includes: [] },
		{ name: 'Country', synonyms: [], includes: [] },
		{ name: 'British', synonyms: [], includes: [] },
		{ name: 'Colonial', synonyms: [], includes: [] },
		{ name: '19th Century', synonyms: [], includes: [] },
		{ name: 'Abstract', synonyms: [], includes: [] },
		{ name: 'Barrister', synonyms: [], includes: [] },
		{ name: 'Impressionist', synonyms: ['Impressionism'], includes: [] },
		{ name: 'Bohemian', synonyms: [], includes: [] },
		{ name: 'Moroccan', synonyms: [], includes: [] },
		{ name: 'Retro', synonyms: [], includes: [] },
		{ name: 'Empire', synonyms: [], includes: [] },
		{ name: 'French Country', synonyms: [], includes: ['French Style'] },
		{ name: 'Venetian Style', synonyms: ['Venetian'], includes: [] },
		{ name: 'Rococo', synonyms: ['Venetian'], includes: [] },
		{ name: 'Mission Style', synonyms: ['Mission'], includes: [] },
		{ name: 'European Style', synonyms: [], includes: [] },
		{ name: 'French Style', synonyms: [], includes: [] },
		{ name: 'Chesterfield', synonyms: [], includes: [] },
		{
			name: 'Danish Modern',
			synonyms: ['Danish'],
			includes: ['Mid Century Modern'],
		},
		{ name: 'Gothic', synonyms: [], includes: [] },
		{ name: 'Victorian', synonyms: [], includes: [] },
		{ name: 'Renaissance', synonyms: [], includes: [] },
		{ name: 'Louis XVI', synonyms: [], includes: [] },
		{ name: 'African', synonyms: [], includes: [] },
		{ name: 'Bauhaus', synonyms: [], includes: [] },
		{ name: 'Egiptian Revival', synonyms: ['Egiptian'], includes: [] },
		{ name: '1980s', synonyms: ['1980', '80s'], includes: [] },
		{ name: '2000s', synonyms: ['2000'], includes: [] },
		{ name: 'Minimalist', synonyms: [], includes: ['Modern'] },
		{ name: 'baroque', synonyms: [], includes: [] },
		{ name: '1950s', synonyms: ['1950', '50s', 'Mid Century'], includes: [] },
		{ name: 'Whimsical', synonyms: [], includes: [] },
		{ name: ' Glam ', synonyms: [], includes: [] },
		{ name: 'Florentine', synonyms: ['Florence'], includes: ['Italian'] },
		{ name: 'Amish', synonyms: [], includes: [] },
		{ name: 'Ming Dinasty', synonyms: ['Ming Dinasty Style'], includes: [] },
		{ name: 'Neoclassical', synonyms: [], includes: [] },
		{ name: 'Cottage', synonyms: [], includes: [] },
		{ name: 'Medieval', synonyms: [], includes: [] },
		{ name: 'Japanese', synonyms: [], includes: ['Asian'] },
		{ name: '18th Century', synonyms: [], includes: [] },
		{
			name: 'American Style',
			synonyms: ['American', 'American Classical'],
			includes: [],
		},
		{ name: 'Regency', synonyms: [], includes: [] },
		{ name: 'Casual', synonyms: [], includes: [] },
		{ name: 'Mongolian', synonyms: [], includes: [] },
		{ name: 'Korean', synonyms: [], includes: ['Asian'] },
		{ name: 'Art Noveau', synonyms: [], includes: [] },
		{ name: 'Impressionist', synonyms: [], includes: [] },
	],
	rooms: ['Living Room', 'Dining Room', 'Office', 'Bedroom'],
	categories: [
		{
			category: 'Seating',
			id: 'seating',
			subCategories: [
				{
					name: 'Chairs',
					id: 'chairs',
					synonyms: [],
					rooms: [],
					height: 35,
					width: 30,
					length: 30,
					weight: 30,
					item: { name: 'Chair', id: 95, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 1,
					types: [
						{
							name: 'Accent Chairs',
							id: 'accent-chairs',
							isNot: ['Chairside Table'],
							synonyms: [
								'Mod Chair',
								'Mod Chairs',
								'Chair Rocker',
								'Chair Rockers',
								'Accent Chair',
								'Accent Chairs',
								'Side Chair',
								'Side Chairs',
								'Statement Chair',
								'Statement Chairs',
								'Decorative Seating',
								'Standout Chair',
								'Standout Chairs',
								'Feature Chair',
								'Feature Chairs',
								'Unique Chair',
								'Unique Chairs',
								'Wingback Chair',
								'Wingback Chairs',
								'Highback Chairs',
								'Slipper Chair',
								'Slipper Chairs',
								'Oversize Chair',
								'Oversize Chairs',
								'Fauteuil Chair',
								'Fauteuil Chairs',
								'Winged Chair',
								'Winged Chairs',
								'Falcon Chair',
								'Falcon Chairs',
								'Bergere',
								'Safari Chair',
								'Safari Chairs',
							],
							rooms: ['Living Room', 'Bedroom'],
							ebayTaxonomy: {
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['35 in'] },
								'Item Length': { required: true, defaultValue: ['30 in'] },
								'Item Width': { required: true, defaultValue: ['30 in'] },
								Type: { required: true, defaultValue: ['Accent Chair'] },
								category: {
									categoryId: '261259',
									categoryName: 'Chairs',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20091',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 992,
								level: 4,
								name: 'Chairs',
								parent_id: 989,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 992],
							},
						},

						{
							name: 'Office Chairs',
							id: 'office-chairs',
							synonyms: [
								'Office Chair',
								'Axion Chair',
								'Engineering High Back Chair',
								'Computer Chair',
								'Computer Chairs',
								'Gaming Chair',
								'Gaming Chairs',
								'Office Chairs',
								'Work Seating',
								'Management Chair',
								'Desk Chair',
								'Desk Chairs',
								'Task Chair',
								'Task Chairs',
								'Business Seating',
								'Professional Chair',
								'Professional Chairs',
								'Executive Chair',
								'Executive Chairs',
							],
							rooms: ['Office'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								MPN: {
									required: true,
									defaultValue: ['Manufacturer Part Number '],
								},
								category: {
									categoryId: '257900',
									categoryName: 'Chairs & Stools',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=11828',
								categoryTreeNodeLevel: 4,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 1000,
								level: 4,
								name: 'Desk Chairs',
								parent_id: 999,
								children: [],
								full_path_taxonomy_ids: [891, 967, 999, 1000],
							},
						},
						{
							name: 'Swivel Chairs',
							id: 'swivel-chairs',
							synonyms: [
								'Swivel Chairs',
								'Swivel Chair',
								'Swivel Upholstered Chair',
								'Swivel Upholstered Chairs',
								'Rotating Seat',
								'Rotating Seats',
								'Spin Chair',
								'Spin Chairs',
								'Turning Chair',
								'Turning Chairs',
								'Revolving Seat',
								'Revolving Seats',
								'Revolving Chair',
								'Revolving Chairs',
								'Twirl Chair',
								'Twirl Chairs',
							],
							rooms: ['Living Room', 'Bedroom'],
							ebayTaxonomy: {
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['35 in'] },
								'Item Length': { required: true, defaultValue: ['30 in'] },
								'Item Width': { required: true, defaultValue: ['30 in'] },
								Type: { required: true, defaultValue: ['Swivel Chair'] },
								category: {
									categoryId: '261259',
									categoryName: 'Chairs',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20091',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 992,
								level: 4,
								name: 'Chairs',
								parent_id: 989,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 992],
							},
						},
						{
							name: 'Dining Chairs',
							id: 'dining-chairs',
							isNot: ['Chairside Table', 'Lounge Chair', 'Accent Armchair'],
							synonyms: [
								'Chair',
								'Chairs',

								'Bistro Chair',
								'Bistro Chairs',
								'Dining Chair',
								'Dining Chairs',
								'Meal Seating',
								'Dinner Chair',
								'Dinner Chairs',
								'Kitchen Chair',
								'Kitchen Chairs',
								'Table Seat',
								'Table Seats',
								'Eating Chair',
								'Eating Chairs',
								'Living Room Chair',
								'Living Room Chairs',
							],
							rooms: ['Living Room', 'Dining Room'],
							ebayTaxonomy: {
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['35 in'] },
								'Item Length': { required: true, defaultValue: ['30 in'] },
								'Item Width': { required: true, defaultValue: ['30 in'] },
								Type: { required: true, defaultValue: ['Dining Chair'] },
								category: {
									categoryId: '261259',
									categoryName: 'Chairs',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20091',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 975,
								level: 4,
								name: 'Dining Chairs',
								parent_id: 973,
								children: [],
								full_path_taxonomy_ids: [891, 967, 973, 975],
							},
						},
					],
				},
				{
					name: 'Sofas',
					id: 'sofas',
					synonyms: [],
					rooms: [],
					height: 35,
					width: 85,
					length: 35,
					weight: 120,
					item: { name: 'Couch - 3 Seat', id: 134, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [
						{
							name: 'Loveseats',
							id: 'loveseats',
							synonyms: [
								'Loveseats',
								'Loveseat',
								'Love Seat',
								'Snuggle Sofa',
								'Snuggle Sofas',
								'Cozy Seat',
								'Cozy Seats',
								'Intimate Sofa',
								'Intimate Sofas',
								'Couple Seating',
								'Small Sofa',
								'Small Sofas',
							],
							rooms: ['Living Room'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['35 in'] },
								'Item Length': { required: true, defaultValue: ['35 in'] },
								'Item Width': { required: true, defaultValue: ['85 in'] },
								Type: { required: true, defaultValue: ['Loveseat'] },
								'Upholstery Fabric': {
									required: true,
									defaultValue: ['Other Fabric'],
								},
								category: {
									categoryId: '38208',
									categoryName: 'Sofas, Armchairs & Couches',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 998,
								level: 4,
								name: 'Couches & Loveseats',
								parent_id: 989,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 998],
							},
						},
						{
							name: 'Sofas',
							id: 'sofas',
							isNot: ['Sofa Tables', 'Sofa Table', 'Entry Table'],
							synonyms: [
								'Sofa',
								'Sofas',
								'Couch',
								'Couches',
								'Triple Sofas',
								'Triple Sofa',
								'Family Couch',
								'Family Couches',
								'Group Seater',
								'Group Seaters',
								'Sleeper Sofa',
								'Sleeper Sofas',
								'Reclining Sofa',
								'Reclining Sofas',
								'Recliner Sofa',
								'Recliner Sofas',
								'Three-Person Sofa',
								'Three-Person Sofas',
								'3-Seater Couches',
								'3-Seater Couch',
								'3-Seater',
								'3 Seat Sofa',
								'3 Seat Sofas',
								'3 Seat Couch',
								'3 Seat Couches',
								'2-Seater Couches',
								'2-Seater Couch',
								'2-Seater',
								'2 Seat Sofa',
								'2 Seat Sofas',
								'2 Seat Couch',
								'2 Seat Couches',
								'4-Seater Couches',
								'4-Seater Couch',
								'4-Seater',
								'4 Seat Sofa',
								'4 Seat Sofas',
								'4 Seat Couch',
								'4 Seat Couches',
							],
							rooms: ['Living Room'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['35 in'] },
								'Item Length': { required: true, defaultValue: ['35 in'] },
								'Item Width': { required: true, defaultValue: ['85 in'] },
								Type: { required: true, defaultValue: ['Sofa'] },
								'Upholstery Fabric': {
									required: true,
									defaultValue: ['Other Fabric'],
								},
								category: {
									categoryId: '38208',
									categoryName: 'Sofas, Armchairs & Couches',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 998,
								level: 4,
								name: 'Couches & Loveseats',
								parent_id: 989,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 998],
							},
						},
						{
							name: 'Sectionals',
							id: 'sectionals',
							synonyms: [
								'Sectional',
								'Sectionals',
								'Modular Sofa',
								'Modular Sofas',
								'Modular Couch',
								'Modular Couches',
								'Sectional Sofa',
								'Sectional Sofas',
								'Sectional Couch',
								'Sectional Couches',
								'L Shaped',
								'L-Shaped',
								'L-Shaped Sofa',
								'L-Shaped Sofas',
								'Corner Sofa',
								'Corner Sofas',
								'Sectional Set',
								'Sectional Sets',
							],
							rooms: ['Living Room'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['35 in'] },
								'Item Length': { required: true, defaultValue: ['35 in'] },
								'Item Width': { required: true, defaultValue: ['85 in'] },
								Type: { required: true, defaultValue: ['Sofa'] },
								'Upholstery Fabric': {
									required: true,
									defaultValue: ['Other Fabric'],
								},
								category: {
									categoryId: '38208',
									categoryName: 'Sofas, Armchairs & Couches',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 998,
								level: 4,
								name: 'Couches & Loveseats',
								parent_id: 989,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 998],
							},
						},
					],
				},
				{
					name: 'Armchairs',
					id: 'armchairs',
					synonyms: [],
					rooms: [],
					height: 20,
					width: 20,
					length: 20,
					weight: 80,
					item: { name: 'Recliner Chair', id: 100, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 1,
					types: [
						{
							name: 'Arm Chairs',
							id: 'arm-chairs',
							synonyms: ['Arm Chair', 'Arm Chairs'],
							rooms: ['Living Room'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['35 in'] },
								'Item Length': { required: true, defaultValue: ['35 in'] },
								'Item Width': { required: true, defaultValue: ['85 in'] },
								Type: { required: true, defaultValue: ['Armchair'] },
								'Upholstery Fabric': {
									required: true,
									defaultValue: ['Other Fabric'],
								},
								category: {
									categoryId: '38208',
									categoryName: 'Sofas, Armchairs & Couches',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 992,
								level: 4,
								name: 'Chairs',
								parent_id: 989,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 992],
							},
						},
						{
							name: 'Rocking Chairs',
							id: 'rocking-chairs',
							synonyms: [
								'Rocking Chair',
								'Rocking Chairs',
								'Rocker',
								'Rockers',
								'Rocker Chair',
								'Rocker Chairs',
								'Rocking Seating',
								'Rocking Seat',
								'Rocking Seats',
								'Glider',
								'Gliders',
								'Swinging Chair',
								'Swinging Chairs',
								'Motion Chair',
								'Motion Chairs',
							],
							rooms: ['Living Room'],
							ebayTaxonomy: {
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['35 in'] },
								'Item Length': { required: true, defaultValue: ['30 in'] },
								'Item Width': { required: true, defaultValue: ['30 in'] },
								Type: { required: true, defaultValue: ['Rocking Chair'] },
								category: {
									categoryId: '261259',
									categoryName: 'Chairs',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20091',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 992,
								level: 4,
								name: 'Chairs',
								parent_id: 989,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 992],
							},
						},
						{
							name: 'Club Chairs',
							id: 'club-chairs',
							synonyms: [
								'Club Chair',
								'Club Chairs',
								'Club Lounge Chair',
								'Comfort Chair',
								'Cozy Seating',
								'Traditional Chair',
								'Social Seating',
								'Low Chair',
								'Petite Seat',
								'Dainty Chair',
								'Minimalist Seating',
							],
							rooms: ['Living Room'],
							ebayTaxonomy: {
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['35 in'] },
								'Item Length': { required: true, defaultValue: ['30 in'] },
								'Item Width': { required: true, defaultValue: ['30 in'] },
								Type: { required: true, defaultValue: ['Club Chair'] },
								category: {
									categoryId: '261259',
									categoryName: 'Chairs',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20091',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 992,
								level: 4,
								name: 'Chairs',
								parent_id: 989,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 992],
							},
						},
						{
							name: 'Recliners',
							id: 'recliners',
							synonyms: [
								'Recliner',
								'Recliners',
								'Arm Chairs & Recliners',
								'Arm Chair & Recliner',
								'Reclining Chair',
								'Reclining Chairs',
								'Relaxation Seat',
								'Relaxation Seats',
								'Comfort Recliner',
								'Comfort Recliners',
								'Upholstered Recliner',
								'Upholstered Recliners',
								'Power Lift Chair',
								'Power Lift Chairs',
								'Power Lift Recliner',
								'Power Lift Recliners',
								'Power Lift',
								'Lift Chair',
								'Lift Chairs',
								'Power Chair',
								'Power Chairs',
								'Power Back Chair',
								'Power Back Chairs',
								'Power Back Recliner',
								'Power Back Recliners',
							],
							rooms: ['Living Room'],
							ebayTaxonomy: {
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['35 in'] },
								'Item Length': { required: true, defaultValue: ['30 in'] },
								'Item Width': { required: true, defaultValue: ['30 in'] },
								Type: { required: true, defaultValue: ['Recliner'] },
								category: {
									categoryId: '261259',
									categoryName: 'Chairs',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20091',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 992,
								level: 4,
								name: 'Chairs',
								parent_id: 989,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 992],
							},
						},
					],
				},
				{
					name: 'Benches',
					id: 'benches',
					isNot: ['Ottoman Bench'],
					synonyms: ['Benches', 'Bench'],
					rooms: ['Living Room', 'Bedroom'],
					height: 16,
					width: 40,
					length: 45,
					weight: 15,
					item: { name: 'Bench - Medium', id: 42, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 1,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultValue: ['Unbranded'] },
						Color: { required: true, defaultValue: ['Multicolor'] },
						'Item Height': { required: true, defaultValue: ['16 in'] },
						'Item Length': { required: true, defaultValue: ['45 in'] },
						'Item Width': { required: true, defaultValue: ['40 in'] },
						Type: { required: true, defaultValue: ['Bench'] },
						category: {
							categoryId: '262980',
							categoryName: 'Benches',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 12369,
						level: 5,
						name: 'Benches',
						parent_id: 990,
						children: [],
						full_path_taxonomy_ids: [891, 967, 989, 990, 12369],
					},
				},
				{
					name: 'Stools',
					id: 'stools',
					synonyms: [],
					rooms: [],
					height: 26,
					width: 14,
					length: 14,
					weight: 15,
					item: { name: 'Stool', id: 504, oject: 'item' },
					vehicle: 'courier',
					helpers: 1,
					types: [
						{
							name: 'Low Stools',
							id: 'low-stools',
							synonyms: [
								'Low Stool',
								'Low Stools',
								'Short Stool',
								'Short Stools',
								'Small Stool',
								'Small Stools',
								'Squat Stool',
								'Squat Stools',
								'Mini Stool',
								'Mini Stools',
								'Low Seat',
								'Low Seats',
							],
							rooms: ['Living Room', 'Dining Room'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['15 in'] },
								'Item Length': { required: true, defaultValue: ['14 in'] },
								'Item Width': { required: true, defaultValue: ['14 in'] },
								Type: { required: true, defaultValue: ['Stool'] },
								category: {
									categoryId: '103431',
									categoryName: 'Bar Stools & Stools',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 971,
								level: 4,
								name: 'Steps & Stools',
								parent_id: 968,
								children: [],
								full_path_taxonomy_ids: [891, 967, 968, 971],
							},
						},
						{
							name: 'Bar Stools',
							id: 'bar-stools',
							synonyms: [
								'Adjustable Height Stool',
								'Seating Stool',
								'Bar Stool',
								'Barstool',
								' Stool ',
								'Barstools',
								'Bar Stools',
								'Bar Chairs',
								'Bar Chair',
								'Bar/Counter Stool',
								'Counter Stool',
								'Counter Stools',
								'Pub Stool',
								'Pub Stools',
								'High Stool',
								'High Stools',
								'Tall Chair',
								'Tall Chairs',
								'Tall Stool',
								'Tall Stools',
							],
							rooms: ['Living Room', 'Dining Room'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['26 in'] },
								'Item Length': { required: true, defaultValue: ['14 in'] },
								'Item Width': { required: true, defaultValue: ['14 in'] },
								Type: { required: true, defaultValue: ['Stool'] },
								category: {
									categoryId: '103431',
									categoryName: 'Bar Stools & Stools',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 971,
								level: 4,
								name: 'Steps & Stools',
								parent_id: 968,
								children: [],
								full_path_taxonomy_ids: [891, 967, 968, 971],
							},
						},
					],
				},
				{
					name: 'Ottomans & Footstools',
					id: 'ottomans-and-footstools',
					synonyms: [
						'Ottoman',
						'Ottomans',
						'Footstool',
						'Footstools',
						'Foot Stool',
						'Foot Stools',
						'Hassock',
						'Hassocks',
						'Poufy',
						'Pouf',
						'Poufs',
						'Tuffet',
						'Tuffets',
						'Footrest',
						'Footrest Ottoman',
						'Footrest Ottomans',
						'Footrest Stool',
						'Footrest Stools',
						'Ottoman Stool',
						'Ottoman Stools',
						'Padded Footrest',
						'Padded Footrests',
					],
					rooms: ['Living Room'],
					height: 18,
					width: 30,
					length: 24,
					weight: 37,
					item: { name: 'Small Ottoman', id: 382, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 1,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultValue: ['Unbranded'] },
						'Item Height': { required: true, defaultValue: ['18 in'] },
						'Item Length': { required: true, defaultValue: ['24 in'] },
						'Item Width': { required: true, defaultValue: ['30 in'] },
						Type: { required: true, defaultValue: ['Ottoman'] },
						category: {
							categoryId: '20490',
							categoryName: 'Ottomans, Footstools & Poufs',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 12468,
						level: 4,
						name: 'Ottomans & Poufs',
						parent_id: 989,
						children: [],
						full_path_taxonomy_ids: [891, 967, 989, 12468],
					},
				},
				{
					name: 'Chaises & Daybeds',
					id: 'chaises-and-daybeds',
					synonyms: [
						'Chaise',
						'Chaises',
						'Chaise Lounge',
						'Lounge Chair',
						'Lounge Chairs',
						'Sunbed',
						'Sunbeds',
						'Daybed',
						'Daybeds',
						'Relaxation Chair',
						'Relaxation Chairs',
						'Lounging Seat',
						'Lounging Seats',
						'Resting Chair',
						'Resting Chairs',
						'Leisure Chair',
						'Leisure Chairs',
						'Chill Seat',
						'Chill Seats',
						'Chaise lounges',
						'Daybed',
						'Daybeds',
						'Futon',
						'Futons',
					],
					rooms: ['Living Room', 'Bedroom'],
					height: 35,
					width: 30,
					length: 60,
					weight: 75,
					item: { name: 'Chaise Lounge', id: 103, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [],
					ebayTaxonomy: {
						Color: { required: true, defaultValue: ['Multicolor'] },
						'Item Height': { required: true, defaultValue: ['35 in'] },
						'Item Length': { required: true, defaultValue: ['60 in'] },
						'Item Width': { required: true, defaultValue: ['30 in'] },
						Material: { required: true, defaultValue: ['Unknown'] },
						Type: { required: true, defaultValue: ['Chaise'] },
						category: {
							categoryId: '261584',
							categoryName: 'Sofas & Chaises',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20091',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 992,
						level: 4,
						name: 'Chairs',
						parent_id: 989,
						children: [],
						full_path_taxonomy_ids: [891, 967, 989, 992],
					},
				},
			],
		},
		{
			category: 'Storage',
			id: 'storage',
			subCategories: [
				{
					name: 'Dressers & Chests of Drawers',
					id: 'dressers-and-chests-of-drawers',
					synonyms: [
						'Dresser',
						'Dressers',
						'Chest of Drawers',
						'Chests of Drawers',
						'Bureau',
						'Highboy',
						'Lowboy',
						'Commode',
						'Gentlemans Chest',
						'Lingerie Chest',
						'Blanket Chest',
						'Accent Chest',
						'Mule Dresser',
						'Chiffonier',
						'Vanity',
					],
					rooms: ['Bedroom'],
					height: 32,
					width: 36,
					length: 18,
					weight: 150,
					item: { name: 'Chest Of Drawers', id: 110, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [],
					ebayTaxonomy: {
						Brand: {
							required: true,
							defaultValue: ['Unbranded'],
						},
						'Item Height': {
							required: true,
							defaultValue: ['32 in'],
						},
						'Item Length': {
							required: true,
							defaultValue: ['18 in'],
						},
						'Item Width': {
							required: true,
							defaultValue: ['36 in'],
						},
						'Number of Drawers': {
							required: true,
							defaultValue: ['3'],
						},
						Type: {
							required: true,
							defaultValue: ['Chest of Drawers'],
						},
						category: {
							categoryId: '114397',
							categoryName: 'Dressers & Chests of Drawers',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 970,
						level: 4,
						name: 'Dressers & Armoires',
						parent_id: 968,
						children: [],
						full_path_taxonomy_ids: [891, 967, 968, 970],
					},
				},
				{
					name: 'Nightstands',
					id: 'nightstands',
					synonyms: [
						'Nightstands',
						'Nightstand',
						'Night Stand',
						'Night Stands',
						'Bedside Table',
						'Bedside Tables',
						'Night Table',
						'Night Tables',
						'Bedside Cabinet',
						'Bedside Cabinets',
						'Bedside Stands',
						'Bedside Stand',
						'Night Cabinet',
						'Night Cabinets',
					],
					rooms: ['Bedroom'],
					height: 25,
					width: 20,
					length: 16,
					weight: 30,
					item: { name: 'Nightstand - Medium', id: 376, oject: 'item' },
					vehicle: 'courier',
					helpers: 2,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultValue: ['Unbranded'] },
						'Item Height': { required: true, defaultValue: ['25 in'] },
						'Item Length': { required: true, defaultValue: ['16 in'] },
						'Item Width': { required: true, defaultValue: ['20 in'] },
						Type: { required: true, defaultValue: ['Nightstand'] },
						category: {
							categoryId: '38199',
							categoryName: 'Nightstands',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 972,
						level: 4,
						name: 'Nightstands',
						parent_id: 968,
						children: [],
						full_path_taxonomy_ids: [891, 967, 968, 972],
					},
				},
				{
					name: 'Armoires & Wardrobes',
					id: 'armoires-and-wardrobes',
					synonyms: [
						'Wardrobe',
						'Wardrobes',
						'Armoire',
						'Armoires',
						'Closet',
						'Closets',
						'Robe Cabinets',
						'Robe Cabinet',
						'Clothing Armoire',
						'Clothing Armoires',
						'Garment Cabinet',
						'Garment Cabinets',
						'Wardrobe Closet',
						'Wardrobe Closets',
						'Closet',
						'Closets',
						'Closet Organizers',
						'Storage Armoires',
						'Wardrobe Cabinets',
					],
					rooms: ['Bedroom'],
					height: 70,
					width: 40,
					length: 24,
					weight: 150,
					item: { name: 'Armoire - Medium', id: 15, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultValue: ['Unbranded'] },
						'Item Height': { required: true, defaultValue: ['70 in'] },
						'Item Length': { required: true, defaultValue: ['24 in'] },
						'Item Width': { required: true, defaultValue: ['40 in'] },
						Type: { required: true, defaultValue: ['Freestanding Wardrobe'] },
						category: {
							categoryId: '103430',
							categoryName: 'Armoires & Wardrobes',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 970,
						level: 4,
						name: 'Dressers & Armoires',
						parent_id: 968,
						children: [],
						full_path_taxonomy_ids: [891, 967, 968, 970],
					},
				},
				{
					name: 'Sideboards & Credenzas',
					id: 'sideboards-and-credenzas',
					isNot: [
						'Console table',
						'Console Buffet Table',
						'Table Lamp',
						'Table Lamps',
					],
					synonyms: [
						'Credenza/Sideboard',
						'Sideboard',
						'Sideboards',
						'Credenza',
						'Credenzas',
						'Buffet',
						'Buffets',
						'Server',
						'Server Cabinet',
						'Server Cabinets',
						'Dining Room Cabinet',
						'Dining Room Cabinets',
						'Hutch',
						'Hutches',
						'Dining Room Sideboard',
						'Dining Room Sideboards',
						'Storage Credenza',
						'Storage Credenzas',
						'Storage Console',
						'Storage Consoles',
						'Dining Room Server',
						'Dining Room Servers',
						'Side Cabinet',
						'Side Cabinets',
						'Dining Room Buffet',
						'Dining Room Buffets',
						'Media Console',
						'Media Consoles',
						'Pantry',
						'Pantries',
						'Cupboard',
						'Cupboards',
					],
					rooms: ['Living Room', 'Dining Room'],
					height: 35,
					width: 60,
					length: 18,
					weight: 100,
					item: { name: 'Kitchen Cabinet', id: 670, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultValue: ['Unbranded'] },
						'Item Height': { required: true, defaultValue: ['35 in'] },
						'Item Length': { required: true, defaultValue: ['18 in'] },
						'Item Width': { required: true, defaultValue: ['60 in'] },
						Type: { required: true, defaultValue: ['Sideboard'] },
						category: {
							categoryId: '183322',
							categoryName: 'Sideboards & Buffets',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 974,
						level: 4,
						name: 'Buffets & China Cabinets',
						parent_id: 973,
						children: [],
						full_path_taxonomy_ids: [891, 967, 973, 974],
					},
				},
				{
					name: 'Bar Carts',
					id: 'bar-carts',
					synonyms: [
						'Bar Cart',
						'Bar Carts',
						'Beverage Cart',
						'Beverage Carts',
						'Drink Trolley',
						'Drink Trolleys',
						'Serving Cart',
						'Serving Carts',
						'Portable Bar',
						'Portable Bars',
						'Cocktail Cart',
						'Cocktail Carts',
						'Liquor Cart',
						'Liquor Carts',
						'Rolling Bars',
						'Mobile Bar',
						'Mobile Bars',
						'Drinks Cart',
						'Drinks Carts',
						'Wine Cart',
						'Wine Carts',
					],
					rooms: ['Living Room', 'Dining Room'],
					height: 30,
					width: 30,
					length: 18,
					weight: 30,
					item: { name: 'Kitchen Cabinet', id: 670, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 1,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultVallue: ['Unbranded'] },
						Type: { required: true, defaultVallue: ['Bar Cart'] },
						'Item Height': { required: true, defaultVallue: ['30 in'] },
						'Item Length': { required: true, defaultVallue: ['18 in'] },
						'Item Width': { required: true, defaultVallue: ['30 in'] },
						Material: { required: true, defaultVallue: ['Unknown'] },
						Color: { required: true, defaultVallue: ['Multicolor'] },
						category: {
							categoryId: '183320',
							categoryName: 'Bar Carts & Serving Carts',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 1055,
						level: 5,
						name: 'Bar Carts & Bars',
						parent_id: 1863,
						children: [],
						full_path_taxonomy_ids: [891, 1031, 1054, 1863, 1055],
					},
				},

				{
					name: 'Storage & Display Cabinets',
					id: 'storage-and-display-cabinets',
					synonyms: [
						'Storage Cabinet',
						'Storage Cabinets',
						'Storage Unit',
						'Storage Units',
						'Filing Cabinet',
						'Filing Cabinets',
						'Shoe Cabinet',
						'Shoe Cabinets',
						'Curio Cabinet',
						'Curio Cabinets',
						'Cabinet',
						'Cabinets',
						'Utility Cabinet',
						'Utility Cabinets',
						'China Cabinet',
						'China Cabinets',
						'Display Cabinet',
						'Display Cabinets',
					],
					rooms: ['Living Room', 'Dining Room'],
					height: 75,
					width: 40,
					length: 18,
					weight: 200,
					item: { name: 'Media Cabinet', id: 352, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [],
					ebayTaxonomy: {
						'Item Height': { required: true, defaultValue: ['75 in'] },
						'Item Length': { required: true, defaultValue: ['18 in'] },
						'Item Width': { required: true, defaultValue: ['40 in'] },
						Type: { required: true, defaultValue: ['Storage Cabinet'] },
						category: {
							categoryId: '261258',
							categoryName: 'Cabinets & Cupboards',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20091',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 974,
						level: 4,
						name: 'Buffets & China Cabinets',
						parent_id: 973,
						children: [],
						full_path_taxonomy_ids: [891, 967, 973, 974],
					},
				},
				{
					name: 'Bookcases & Shelving',
					id: 'bookcases-and-shelving',
					isNot: [
						'Console Table',
						'Coffee Table',
						'Wall Clock',
						'Curio Cabinet',
					],
					synonyms: [
						'Bookcase',
						'Bookcases',
						'Shelving',
						'Shelve',
						'Shelf',
						'Shelfs',
						'Bookshelf',
						'Bookshelve',
						'Bookshelves',
						'Bookstand',
						'Bookstands',
						'Shelving Unit',
						'Shelving Units',
						'Book Rack',
						'Book Racks',
						'Library Shelf',
						'Library Shelves',
						'Display Shelf',
						'Display Shelves',
						'Wall Shelf',
						'Wall Shelves',
					],
					rooms: ['Living Room', 'Bedroom'],
					height: 70,
					width: 30,
					length: 12,
					weight: 50,
					item: { name: 'Bookcase - Medium', id: 49, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultValue: ['Unbranded'] },
						'Item Height': { required: true, defaultValue: ['70 in'] },
						'Item Length': { required: true, defaultValue: ['12 in'] },
						'Item Width': { required: true, defaultValue: ['30 in'] },
						'Number of Shelves': { required: true, defaultValue: ['4'] },
						Type: { required: true, defaultValue: ['Bookcase'] },
						category: {
							categoryId: '3199',
							categoryName: 'Bookcases & Shelving',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 991,
						level: 4,
						name: 'Bookshelves',
						parent_id: 989,
						children: [],
						full_path_taxonomy_ids: [891, 967, 989, 991],
					},
				},

				{
					name: 'Trunks & Chests',
					id: 'trunks-and-chests',
					synonyms: ['Trunk', 'Trunks', 'Chest', 'Chests'],
					rooms: [],
					height: 20,
					width: 36,
					length: 20,
					weight: 40,
					item: { name: 'Media Cabinet', id: 352, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 1,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultValue: ['Unbranded'] },
						'Item Height': { required: true, defaultValue: ['20 in'] },
						'Item Length': { required: true, defaultValue: ['20 in'] },
						'Item Width': { required: true, defaultValue: ['36 in'] },
						Type: { required: true, defaultValue: ['Chest'] },
						category: {
							categoryId: '125085',
							categoryName: 'Trunks & Chests',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 12370,
						level: 5,
						name: 'Trunks',
						parent_id: 990,
						children: [],
						full_path_taxonomy_ids: [891, 967, 989, 990, 12370],
					},
				},
			],
		},
		{
			category: 'Beds',
			id: 'beds',
			subCategories: [
				{
					name: 'Headboards',
					id: 'headboards',
					synonyms: [
						' Headboard ',
						'Headboards',
						'Head board',
						'Head boards',
						'Bed Backboard',
						'Bed Backboards',
						'Bed Headrest',
						'Bed Headrests',
						'Sleep Panel',
						'Sleep Panels',
						'Pillow Back',
						'Pillow Backs',
						'Headrest Panel',
						'Headrest Panels',
					],
					rooms: ['Bedroom'],
					height: 48,
					width: 60,
					length: 3,
					weight: 30,
					item: { name: 'Headboard', id: 256, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 1,
					types: [],
					ebayTaxonomy: {
						Type: { required: true, defaultvalue: ['Headboard'] },
						category: {
							categoryId: '261254',
							categoryName: 'Headboards & Footboards',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=261754',
						categoryTreeNodeLevel: 4,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 12456,
						level: 5,
						name: 'Headboards',
						parent_id: 969,
						children: [],
						full_path_taxonomy_ids: [891, 967, 968, 969, 12456],
					},
				},
				{
					name: 'Bed Frames',
					id: 'bed-frames',
					synonyms: [
						' Bed ',
						'Beds',
						'Bedsteads',
						'Bed Frame',
						'Bed Frames',
						'Bunkbed',
						'Bunk Bed',
						'Bunk Beds',
						'Crib',
						'Infant Bed',
						'Infant Bed/Crib',
						'Bedframe',
						'Bedframes',
						'Bed Platform',
						'Bed Platforms',
						'Beds & Frames',
						'Frame & Mattress',
						'Sleeping Foundation',
						'Sleeping Foundations',
						'Mattress Support',
						'Mattress Supports',
						'Frame Structure',
						'Frame Structures',
					],
					rooms: ['Bedroom'],
					height: 16,
					width: 60,
					length: 80,
					weight: 70,
					item: { name: 'Full Bed', id: 229, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [],
					ebayTaxonomy: {
						'Compatible Mattress Size': {
							required: true,
							defaultValue: ['Queen'],
						},
						Material: { required: true, defaultValue: ['Unknown'] },
						Type: { required: true, defaultValue: ['Bed Frame'] },
						category: {
							categoryId: '261253',
							categoryName: 'Beds & Bed Frames',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=261754',
						categoryTreeNodeLevel: 4,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 12456,
						level: 5,
						name: 'Headboards',
						parent_id: 969,
						children: [],
						full_path_taxonomy_ids: [891, 967, 968, 969, 12456],
					},
				},
			],
		},
		{
			category: 'Tables & Desks',
			id: 'tables-and-desks',
			subCategories: [
				{
					name: 'Tables',
					id: 'tables',
					isNot: [],
					synonyms: [],
					rooms: [],
					height: 30,
					width: 40,
					length: 72,
					weight: 100,
					item: { name: 'Kitchen Table - 4 Seats', id: 71, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [
						{
							name: 'Coffee tables',
							id: 'coffee-tables',
							synonyms: [
								'Coffee Table',
								'Coffee Tables',
								'Cocktail table',
								'Cocktail tables',
								'Center table',
								'Center tables',
								'Living room table',
								'Living room tables',
								'Wine Table',
								'Wine Tables',
								'Lounge table',
								'Lounge tables',
							],
							rooms: ['Living Room'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								'Item Height': { required: true, defaultValue: ['19 in'] },
								'Item Length': { required: true, defaultValue: ['48 in'] },
								'Item Width': { required: true, defaultValue: ['36 in'] },
								Type: { required: true, defaultValue: ['Coffee Table'] },
								category: {
									categoryId: '38204',
									categoryName: 'Tables',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 12371,
								level: 5,
								name: 'Coffee Tables',
								parent_id: 993,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 993, 12371],
							},
						},
						{
							name: 'Console tables',
							id: 'console-tables',
							synonyms: [
								'Console table',
								'Console tables',
								'Hallway table',
								'Hallway tables',
								'Entryway table',
								'Entryway tables',
								'Sofa table',
								'Entry Table',
								'Entry Tables',
								'Sofa tables',
								'Buffet table',
								'Buffet tables',
							],
							rooms: ['Living Room'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								'Item Height': { required: true, defaultValue: ['30 in'] },
								'Item Length': { required: true, defaultValue: ['16 in'] },
								'Item Width': { required: true, defaultValue: ['42 in'] },
								Type: { required: true, defaultValue: ['Console Table'] },
								category: {
									categoryId: '38204',
									categoryName: 'Tables',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 11355,
								level: 5,
								name: 'Console & Sofa Tables',
								parent_id: 994,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 994, 11355],
							},
						},

						{
							name: 'Accent & Side tables',
							id: 'accent-and-side-tables',
							synonyms: [
								'Side table',
								'Side tables',
								'Chairside Table',
								'Chairside Tables',
								'Sofa Table',
								'Sofa Tables',
								'Tray table',
								'Tray tables',
								'Bistro Table',
								'Bistro Tables',
								'End Pedestal Table',
								'End Pedestal Tables',
								'End table',
								'End tables',
								'Accent table',
								'Accent tables',
								'Occasional table',
								'Occasional tables',
								'Lamp table',
								'Lamp tables',
								'Pedestal table',
								'Pedestal tables',
								'Nesting table',
								'Nesting tables',
							],
							rooms: ['Living Room', 'Bedroom'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								'Item Height': { required: true, defaultValue: ['19 in'] },
								'Item Length': { required: true, defaultValue: ['21 in'] },
								'Item Width': { required: true, defaultValue: ['21 in'] },
								Type: { required: true, defaultValue: ['End & Side Tables'] },
								category: {
									categoryId: '38204',
									categoryName: 'Tables',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 12372,
								level: 5,
								name: 'End Tables',
								parent_id: 993,
								children: [],
								full_path_taxonomy_ids: [891, 967, 989, 993, 12372],
							},
						},
						{
							name: 'Dining tables',
							id: 'dining-tables',
							synonyms: [
								'Dining table',
								'Dining tables',
								'Kitchen table',
								'Kitchen tables',
								'Dinner table',
								'Dinner tables',
								'Breakfast table',
								'Breakfast tables',
								'Banquet table',
								'Banquet tables',
								'Formal table',
								'Formal tables',
								'Formal tables',
								'Adjustable Table',
								'Adjustable Tables',
							],
							rooms: ['Dining Room'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								'Item Height': { required: true, defaultValue: ['30 in'] },
								'Item Length': { required: true, defaultValue: ['65 in'] },
								'Item Width': { required: true, defaultValue: ['42 in'] },
								Type: { required: true, defaultValue: ['Dining Table'] },
								category: {
									categoryId: '38204',
									categoryName: 'Tables',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 977,
								level: 4,
								name: 'Kitchen & Dining Tables',
								parent_id: 973,
								children: [],
								full_path_taxonomy_ids: [891, 967, 973, 977],
							},
						},
					],
				},
				{
					name: 'Desks',
					id: 'desks',
					synonyms: [],
					rooms: [],
					height: 34,
					width: 42,
					length: 21,
					weight: 30,
					item: { name: 'Desk - Medium', id: 163, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [
						{
							name: 'Desks',
							id: 'desks',
							synonyms: [
								'Desk',
								'Desks',
								'Executive Desk',
								'Executive Desks',
								'Workstation',
								'Workstations',
								'Writing Desk',
								'Writing Desks',
								'Office Desk',
								'Office Desks',
								'Study Table',
								'Study Tables',
								'Computer Desk',
								'Computer Desks',
								'Standing Desk',
								'Standing Desks',
								'Sit/stand up Desk',
								'Stand up Desk',
								'Stand up Desks',
								'Height-adjustable Desk',
								'Height-adjustable Desks',
							],
							rooms: ['Living Room', 'Office', 'Bedroom'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['34 in'] },
								'Item Length': { required: true, defaultValue: ['21 in'] },
								'Item Width': { required: true, defaultValue: ['42 in'] },
								Type: { required: true, defaultValue: ['Computer Desk'] },
								category: {
									categoryId: '88057',
									categoryName: 'Home Office Desks',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 1001,
								level: 4,
								name: 'Desks',
								parent_id: 999,
								children: [],
								full_path_taxonomy_ids: [891, 967, 999, 1001],
							},
						},
						{
							name: 'Secretary Desks',
							id: 'secretary-desks',
							synonyms: [
								'Secretary Desk',
								'Secretary Desks',
								'Writing Desk with Hutch',
								'Writing Desks with Hutch',
								'Desk w/Hutch',
								'Bureau Desk',
								'Bureau Desks',
								'Drop-front Desk',
								'Drop-front Desks',
							],
							rooms: ['Living Room'],
							ebayTaxonomy: {
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['45 in'] },
								'Item Length': { required: true, defaultValue: ['21 in'] },
								'Item Width': { required: true, defaultValue: ['35 in'] },
								Type: { required: true, defaultValue: ['Secretary'] },
								category: {
									categoryId: '261262',
									categoryName: 'Desks & Secretaries',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20091',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 1001,
								level: 4,
								name: 'Desks',
								parent_id: 999,
								children: [],
								full_path_taxonomy_ids: [891, 967, 999, 1001],
							},
						},
						{
							name: 'Vanity Desks',
							id: 'vanity-desks',
							synonyms: [
								'Vanity Desk',
								'Vanity Desks',
								'Vanity Table',
								'Vanity Tables',
								'Dressing Table',
								'Dressing Tables',
								'Makeup Table',
								'Makeup Tables',
							],
							rooms: ['Bedroom'],
							ebayTaxonomy: {
								Color: { required: true, defaultValue: ['Multicolor'] },
								'Item Height': { required: true, defaultValue: ['31 in'] },
								'Item Length': { required: true, defaultValue: ['20 in'] },
								'Item Width': { required: true, defaultValue: ['42 in'] },
								Type: { required: true, defaultValue: ['Vanity Table'] },
								category: {
									categoryId: '261587',
									categoryName: 'Vanities & Vanity Tables',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20091',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 12470,
								level: 4,
								name: 'Vanity Tables',
								parent_id: 968,
								children: [],
								full_path_taxonomy_ids: [891, 967, 968, 12470],
							},
						},
					],
				},
			],
		},
		{
			category: 'Decor',
			id: 'decor',
			subCategories: [
				{
					name: 'Decorative Accessories',
					id: 'decorative-accessories',
					synonyms: [],
					rooms: [],
					height: 12,
					width: 6,
					length: 6,
					weight: 5,
					item: { name: 'Mirror', id: 361, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [
						{
							name: 'Vases',
							id: 'vases',
							synonyms: [
								'Vase',
								'Vases',
								'Flower Container',
								'Flower Containers',
								'Pot',
								'Pots',
								'Decorative Pot',
								'Decorative Pots',
								'Floral Holder',
								'Floral Holders',
								'Jar',
								'Jars',
								'Ornamental Jar',
								'Ornamental Jars',
								'Plant Vessel',
								'Plant Vessels',
								'Ceramic Container',
								'Ceramic Containers',
								'Glass Vessel',
								'Glass Vessels',
								'Pottery Jar',
								'Pottery Jars',
								'Porcelain Holder',
								'Porcelain Holders',
								'Crystal Container',
								'Crystal Containers',
							],
							rooms: ['Dining Room', 'Bedroom', 'Living Room'],
							ebayTaxonomy: {
								category: {
									categoryId: '39439',
									categoryName: 'Vases & Urns',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=2212',
								categoryTreeNodeLevel: 4,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 1026,
								level: 4,
								name: 'Vases',
								parent_id: 12490,
								children: [],
								full_path_taxonomy_ids: [891, 1002, 12490, 1026],
							},
						},
						{
							name: 'Sculptures & Statues',
							id: 'sculptures-and-statues',
							synonyms: [
								'Sculpture',
								'Sculptures',
								'Statue',
								'Statues',
								'Art Sculpture',
								'Art Sculptures',
								'Decorative Sculpture',
								'Decorative Sculptures',
								'Figurine',
								'Figurines',
								'Art Statue',
								'Art Statues',
								'Ornamental Sculpture',
								'Ornamental Sculptures',
								'Decor Statue',
								'Statue Decor',
								'Sculpture Decor',
								'Sculptural Art',
							],
							rooms: ['Living Room', 'Bedroom', 'Living Room'],
							ebayTaxonomy: {
								Type: { required: true, defaultValue: ['Sculpture'] },
								category: {
									categoryId: '553',
									categoryName: 'Art Sculptures',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=550',
								categoryTreeNodeLevel: 2,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 2869,
								level: 4,
								name: 'Statues',
								parent_id: 12490,
								children: [],
								full_path_taxonomy_ids: [891, 1002, 12490, 2869],
							},
						},
						{
							name: 'Decorative Accents',
							id: 'decorative-accents',
							synonyms: [
								'Decorative Accent',
								'Decorative Accents',
								'Unique Clock',
								'Unique Clocks',
								'Clock',
								'Clocks',
								'Antique Clock',
								'Antique Clocks',
								'Grandfather clock',
								'Grandfather clocks',
								'Candlestick',
								'Candlesticks',
								'Candle holder',
								'Candle holders',
								'Candleholder',
								'Candleholders',
								'Succulent Decor',
								'Plant',
								'Plants',
								'Collectible',
								'Collectibles',
								' Shutters ',
							],
							rooms: ['Living Room', 'Bedroom', 'Living Room'],
							ebayTaxonomy: {
								category: {
									categoryId: '73467',
									categoryName: 'Other Decorative Collectibles',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=13777',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 12490,
								level: 3,
								name: 'Home Accents',
								parent_id: 1002,
							},
						},
						{
							name: 'Kitchen Accessoires',
							id: 'kitchen-accessoires',
							synonyms: [
								'Kitchen Accessory',
								'Kitchen Accessoires',
								'Bowl',
								'Bowls',
								'Glasses',
								'Cup',
								'Cups',
								'Decorative Bowl',
								'Decorative Bowls',
								'Kitchenware',
								'Cookware',
								'Kitchen Tools',
								'Culinary Equipment',
								'Kitchen Implements',
								'Food Preparation Items',
								'Kitchen Supplies',
								'Cooking Utensils',
								'Culinary Gear',
								'Kitchen Essentials',
								'Plate',
								'Plates',
								'Set of Plates',
							],
							rooms: ['Dining Room'],
							ebayTaxonomy: {
								Type: { required: true, defaultValue: ['Utensil'] },
								Material: { required: true, defaultValue: ['Plastic'] },
								Brand: { required: true, defaultValue: ['Unbranded'] },
								category: {
									categoryId: '177054',
									categoryName: 'Bowls & Plates',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=259329',
								categoryTreeNodeLevel: 4,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 6221,
								level: 2,
								name: 'Kitchen Supplies',
								parent_id: 562,
							},
						},
					],
				},
				{
					name: 'Room Dividers',
					id: 'room-dividers',
					synonyms: [
						'Room Divider',
						'Room Dividers',
						'Wall Divider',
						'Wall Dividers',
					],
					rooms: ['Living Room', 'Bedroom'],
					height: 72,
					width: 40,
					length: 2,
					weight: 20,
					item: { name: 'Mirror', id: 361, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 1,
					types: [],
					ebayTaxonomy: {
						Type: { required: true, defaultValue: ['Room Divider'] },
						Brand: { required: true, defaultValue: ['Unbranded'] },
						category: {
							categoryId: '31601',
							categoryName: 'Screens & Room Dividers',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=3197',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 12216,
						level: 4,
						name: 'Room Dividers',
						parent_id: 989,
						children: [],
						full_path_taxonomy_ids: [891, 967, 989, 12216],
					},
				},
				{
					name: 'Mirrors',
					id: 'mirrors',
					synonyms: [],
					rooms: [],
					height: 30,
					width: 24,
					length: 2,
					weight: 30,
					item: { name: 'Mirror', id: 361, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 2,
					types: [
						{
							name: 'Wall Mirrors',
							id: 'wall-mirrors',
							synonyms: ['Wall Mirrors', 'Mirror'],
							rooms: ['Living Room', 'Bedroom'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								'Item Height': { required: true, defaultValue: ['30 in'] },
								'Item Length': { required: true, defaultValue: ['2 in'] },
								'Item Width': { required: true, defaultValue: ['24 in'] },
								category: {
									categoryId: '20580',
									categoryName: 'Mirrors',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=10033',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 1021,
								level: 3,
								name: 'Mirrors',
								parent_id: 1002,
								children: [],
								full_path_taxonomy_ids: [891, 1002, 1021],
							},
						},
						{
							name: 'Full Length & Floor Mirrors',
							id: 'full-length-and-floor-mirrors',
							synonyms: [
								'Floor Mirror',
								'Floor Mirrors',
								'Full Length Mirror',
								'Full Length Mirrors',
							],
							rooms: ['Living Room', 'Bedroom'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								'Item Height': { required: true, defaultValue: ['30 in'] },
								'Item Length': { required: true, defaultValue: ['2 in'] },
								'Item Width': { required: true, defaultValue: ['24 in'] },
								category: {
									categoryId: '20580',
									categoryName: 'Mirrors',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=10033',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 1021,
								level: 3,
								name: 'Mirrors',
								parent_id: 1002,
								children: [],
								full_path_taxonomy_ids: [891, 1002, 1021],
							},
						},
					],
				},
				{
					name: 'Rugs & Carpets',
					id: 'rugs-and-carpets',
					synonyms: [],
					rooms: [],
					height: 90,
					width: 78,
					length: 2,
					weight: 20,
					item: { name: 'Medium Rug', id: 463, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 1,
					types: [
						{
							name: 'Runners',
							id: 'runners',
							synonyms: [
								'Runner',
								'Runners',
								'Carpet Runner',
								'Carpet Runners',
								'Hallway Rug',
								'Hallway Rugs',
								'Entryway Carpet',
								'Entryway Carpets',
								'Rug Strip',
								'Rug Strips',
								'Floor Mat',
								'Floor Mats',
							],
							rooms: ['Living Room', 'Bedroom'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								Type: { required: true, defaultValue: ['Textured'] },
								category: {
									categoryId: '20574',
									categoryName: 'Runners',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20571',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 929,
								level: 3,
								name: 'Rugs',
								parent_id: 927,
								children: [],
								full_path_taxonomy_ids: [891, 927, 929],
							},
						},
						{
							name: 'Carpets',
							id: 'carpets',
							synonyms: [
								'Carpet',
								'Carpets',
								'Rug',
								'Rugs',
								'Area Rug',
								'Area Rugs',
								'Room Carpet',
								'Room Carpets',
								'Floor Covering',
								'Floor Coverings',
								'Rug Pad',
								'Rug Pads',
								'Floor Rug',
								'Floor Rugs',
							],
							rooms: ['Living Room', 'Bedroom'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								Type: { required: true, defaultValue: ['Area Rug'] },
								category: {
									categoryId: '262983',
									categoryName: 'Area Rugs',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=20571',
								categoryTreeNodeLevel: 3,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 929,
								level: 3,
								name: 'Rugs',
								parent_id: 927,
								children: [],
								full_path_taxonomy_ids: [891, 927, 929],
							},
						},
					],
				},
				{
					name: 'Wall Art',
					id: 'wall-art',
					synonyms: [],
					rooms: [],
					height: 30,
					width: 18,
					length: 2,
					weight: 5,
					item: { name: 'Medium Painting - 5ft-7ft', id: 390, oject: 'item' },
					vehicle: 'courier',
					helpers: 1,
					types: [
						{
							name: 'Paintings',
							id: 'paintings',
							synonyms: [
								'Painting',
								'Paintings',
								'Artwork',
								'Artworks',
								'Canva',
								'Canvas',
								'Canvas Art',
								'Art Print',
								'Art Prints',
								'Wall Art',
								'Oil Painting',
								'Oil Paintings',
								'Watercolor Art',
								'Color Intaglio',
							],
							rooms: ['Living Room', 'Dining Room', 'Bedroom'],
							ebayTaxonomy: {
								Type: { required: true, defaultValue: ['Acrylic Painting'] },
								category: {
									categoryId: '551',
									categoryName: 'Paintings',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=550',
								categoryTreeNodeLevel: 2,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 1029,
								level: 4,
								name: 'Wall Hangings',
								parent_id: 1027,
							},
						},
						{
							name: 'Picture Frames',
							id: 'picture-frames',
							synonyms: [
								'Picture frame',
								'Picture frames',
								'Photo Frame',
								'Photo Frames',
								'Framed Picture',
								'Framed Pictures',
								'Framed',
								'Wall Frame',
								'Wall Frames',
							],
							rooms: ['Living Room', 'Bedroom'],
							ebayTaxonomy: {
								Brand: { required: true, defaultValue: ['Unbranded'] },
								category: {
									categoryId: '37575',
									categoryName: 'Frames & Supplies',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=37573',
								categoryTreeNodeLevel: 4,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 2279,
								level: 4,
								name: 'Picture Frames',
								parent_id: 1024,
								children: [
									{
										id: 11299,
										level: 5,
										name: 'Collage Frames',
										parent_id: 2279,
										children: [],
										full_path_taxonomy_ids: [891, 1002, 1024, 2279, 11299],
									},
								],
								full_path_taxonomy_ids: [891, 1002, 1024, 2279],
							},
						},
						{
							name: 'Wall Decorative Accents',

							id: 'wall-decorative-accents',
							synonyms: [
								'Wall Decoration',
								'Wall Decorations',
								'Wall Decorative Accent',
								'Wall Decorative Accents',
								'Wall Hanging',
								'Wall Hangings',
								'Wall Ornament',
								'Wall Ornaments',
								'Wall Artifact',
								'Wall Artifacts',
								'Decorative Wall Piece',
								'Decorative Wall Pieces',
								'Wall Sculpture',
								'Wall Sculptures',
							],
							rooms: ['Living Room', 'Dining Room', 'Bedroom'],
							ebayTaxonomy: {
								category: {
									categoryId: '83904',
									categoryName: 'Wall Hangings',
								},
								parentCategoryTreeNodeHref:
									'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=160656',
								categoryTreeNodeLevel: 4,
								leafCategoryTreeNode: true,
							},
							etsyTaxonomy: {
								id: 1029,
								level: 4,
								name: 'Wall Hangings',
								parent_id: 1027,
							},
						},
					],
				},
			],
		},
		{
			category: 'Lighting',
			id: 'lighting',
			subCategories: [
				{
					name: 'Table Lamps',
					id: 'table-lamps',
					synonyms: [
						'Table lamp',
						'Table lamps',
						'Bedside Lamp',
						'Bedside Lamps',
						'Nightstand Lamp',
						'Nightstand Lamps',
						'Accent Table Lamp',
						'Accent Table Lamps',
						'Reading Lamp',
						'Column Lamp',
						'Reading Lamps',
						'Decorative Table Lamp',
						'Decorative Table Lamps',
					],
					rooms: ['Living Room', 'Bedroom'],
					height: 25,
					width: 6,
					length: 6,
					weight: 4,
					item: { name: 'Lamp - Table', id: 283, oject: 'item' },
					vehicle: 'courier',
					helpers: 1,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultValue: ['Unbranded'] },
						Type: { required: true, defaultValue: ['Table Lamp'] },
						category: {
							categoryId: '261713',
							categoryName: 'Lamps',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=1404',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 2815,
						level: 5,
						name: 'Table Lamps',
						parent_id: 12351,
						children: [],
						full_path_taxonomy_ids: [891, 1086, 1089, 12351, 2815],
					},
				},
				{
					name: 'Desk Lamps',
					id: 'desk-lamps',
					synonyms: [
						'Desk Lamp',
						'Desk Lamps',
						'Office Lamp',
						'Office Lamps',
						'Office Desk Lamp',
						'Office Desk Lamps',
						'Task Lamp',
						'Task Lamps',
						'Workstation Lamp',
						'Workstation Lamps',
						'Study Lamp',
						'Study Lamps',
						'Study Desk Lamp',
						'Study Desk Lamps',
						'Desktop Lamp',
						'Desktop Lamps',
					],
					rooms: ['Living Room', 'Office', 'Bedroom'],
					height: 20,
					width: 20,
					length: 20,
					weight: 5,
					item: { name: 'Lamp - Table', id: 283, oject: 'item' },
					vehicle: 'courier',
					helpers: 1,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultValue: ['Unbranded'] },
						Type: { required: true, defaultValue: ['Desk Lamp'] },
						category: {
							categoryId: '261713',
							categoryName: 'Lamps',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=1404',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 2813,
						level: 5,
						name: 'Desk Lamps',
						parent_id: 12351,
						children: [],
						full_path_taxonomy_ids: [891, 1086, 1089, 12351, 2813],
					},
				},
				{
					name: 'Ceiling & Wall Lamps',
					id: 'ceiling-and-wall-lamps',
					synonyms: [
						'Celing Light',
						'Celing Lights',
						'Chandelier',
						'Ceiling Lamp',
						'Ceiling Lamps',
						'Wall Light',
						'Wall Lights',
						'Wall Lamp',
						'Wall Lamps',
						'Overhead Lamp',
						'Overhead Lamps',
						'Pendant',
						'Pendants',
						'Pendant Lamp',
						'Pendant Lamps',
						'Hanging Ceiling Lamp',
						'Hanging Ceiling Lamps',
						'Chandelier',
						'Chandeliers',
						'Ceiling Fixture',
						'Ceiling Fixtures',
					],
					rooms: ['Living Room', 'Dining Room', 'Office', 'Bedroom'],
					height: 20,
					width: 20,
					length: 20,
					weight: 5,
					item: { name: 'Lamp - Table', id: 283, oject: 'item' },
					vehicle: 'courier',
					helpers: 1,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultValue: ['Unbranded'] },
						Type: { required: true, defaultValue: ['Ceiling Light'] },
						category: {
							categoryId: '261713',
							categoryName: 'Lamps',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=1404',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 12349,
						level: 4,
						name: 'Wall Lights',
						parent_id: 1088,
					},
				},
				{
					name: 'Floor Lamps',
					id: 'floor-lamps',
					synonyms: [
						'Floor Lamp',
						'Floor Lamps',
						'Standing Lamp',
						'Standing Lamps',
						'Floor Illumination',
						'Reading Floor Lamp',
						'Reading Floor Lamps',
						'Ambient Floor Lamp',
						'Ambient Floor Lamps',
						'Uplighters',
						'Lamp',
					],
					rooms: ['Living Room', 'Dining Room', 'Office', 'Bedroom'],
					height: 20,
					width: 20,
					length: 20,
					weight: 5,
					item: { name: 'Lamp - Floor', id: 282, oject: 'item' },
					vehicle: 'pickup_truck',
					helpers: 1,
					types: [],
					ebayTaxonomy: {
						Brand: { required: true, defaultValue: ['Unbranded'] },
						Type: { required: true, defaultValue: ['Floor Lamp'] },
						category: {
							categoryId: '261713',
							categoryName: 'Lamps',
						},
						parentCategoryTreeNodeHref:
							'https://api.ebay.com/commerce/taxonomy/v1/category_tree/0/get_category_subtree?category_id=1404',
						categoryTreeNodeLevel: 3,
						leafCategoryTreeNode: true,
					},
					etsyTaxonomy: {
						id: 2814,
						level: 5,
						name: 'Floor Lamps',
						parent_id: 12351,
						children: [],
						full_path_taxonomy_ids: [891, 1086, 1089, 12351, 2814],
					},
				},
			],
		},
	],
};
